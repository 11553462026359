<template lang="pug">
    .w-full.max-w-screen-lg.mx-auto.text-center.p-4
        .py-56(v-if='! dadosPix')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting')
        div(v-else)
            .border
                .border-b.p-4
                    p.font-bold.text-gray-700 Pagamento no Pix
                .p-4.bg-gray-100(v-if='timer')
                    p.text-2xl.font-bold.my-4 Quase lá...
                    p.mb-4.px-8
                        span Pague seu Pix dentro de&nbsp;
                        span.font-bold.text-green-600 {{ getTimer() }}
                        span &nbsp;para garantir sua compra.
                    .bg-yellow-200.border.border-yellow-300.py-2.rounded-full.my-2.inline-block.px-6 Aguardando pagamento
                    .bg-white.p-2.mt-4.rounded-2xl.overflow-hidden
                        p Valor do Pix:
                        p.text-lg.text-blue-600.font-bold {{ $root.formatPrice(parseFloat(dadosPix.amount)) }}
                        canvas#canvas.mx-auto
                        input#clipboardInput.w-full.opacity-0.cursor-default(type='text' v-model='dadosPix.pix_qrcode')
                        .flex.flex-wrap
                            .w-full
                                b-button(type='is-primary' icon-pack='jam' icon-left='jam-files' @click='copyClipboard()') Copiar chave Pix
                            p.mt-4.w-full Após copiar o código, abra seu aplicativo de pagamento onde você utiliza o Pix.
                    .mt-4
                        b-button.bg-green-500.text-white(class='hover:text-white' @click="$router.push({ name: 'carrinho' })") Continuar
                .p-4.bg-gray-100(v-else)
                    .bg-white.p-2.mt-4.rounded-2xl.overflow-hidden
                        p.text-2xl.font-bold.my-4 Seu pagamento está em análise...
                        .py-8
                            b-icon.text-green-500(style='font-size:6rem' pack='jam' icon='jam-task-list')
                        p Valor do Pix:
                        p.text-lg.text-blue-600.font-bold {{ $root.formatPrice(parseFloat(dadosPix.amount)) }}
                        p.mt-8 Seu pagamento está sendo analisado e poderá ser liberado em breve.
                    .mt-4
                        b-button.bg-green-500.text-white(class='hover:text-white' @click="$router.push({ name: 'carrinho' })") Continuar
</template>

<script>
    import { Pagamento } from '../../middleware'
    import qrcode from 'qrcode'
    export default {
        created () {
            var medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
            Pagamento.getCarrinho({ie_associado_med_empresa: medempresa, novo: true}).then(response => {
                if (response.status === 200) {

                    if (response.data.tem_compra_pix) {
                        this.dadosPix = response.data.tem_compra_pix

                        const moment = this.$root.moment
                        let expirationDate = moment(this.dadosPix.pix_expiration_date)

                        // Adiciona +5 minutos ao expiration_date
                        // expirationDate = expirationDate.add(5, 'minutes')

                        const now = moment()
                        let timer = expirationDate.diff(now, 'seconds')
                        
                        if (timer > 0) {
                            this.timer = timer
                            window.setTimeout(() => {

                                let canvas = document.getElementById('canvas')
                                if (canvas) qrcode.toCanvas(canvas, this.dadosPix.pix_qrcode, err => {
                                    if (err) console.log(err)
                                })

                                this.intervalTimer = window.setInterval(() => {
                                    if (this.timer > 0) {
                                        this.timer--
                                    } else if (this.timer <= 0) {
                                        window.clearInterval(this.intervalTimer)
                                    }
                                }, 1000)

                            })
                        }

                    } else {
                        this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Sem Pix em processameto' })
                        if (this.$route.name !== 'carrinho') this.$router.push({ name: 'carrinho' })
                    }
                }
            })
        },
        data () {
            return {
                waiting: true,
                dadosPix: null,
                timer: null,
                intervalTimer: null
            }
        },
        methods: {
            getTimer () {
                let minutes = `${ Math.floor(this.timer / 60) > 9 ? Math.floor(this.timer / 60) : '0' + Math.floor(this.timer / 60) }`
                let seconds = `${ (this.timer % 60) > 9 ? (this.timer % 60) : '0' + (this.timer % 60) }`
                return `${ minutes }:${ seconds }`
            },
            copyClipboard () {
                let clipboardInput = document.getElementById("clipboardInput")
                clipboardInput.select()
                clipboardInput.setSelectionRange(0, 99999); /* For mobile devices */
                document.execCommand("copy")
                this.$buefy.toast.open({ type: 'is-success', position: 'is-bottom', message: 'Código copiado' })
            }
        }
    }
</script>